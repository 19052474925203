const selectItems = [
  {
    name: 0,
    value: 0,
  },
  {
    name: 1,
    value: 1,
  },
  {
    name: 2,
    value: 2,
  },
  {
    name: 3,
    value: 3,
  },
  {
    name: 4,
    value: 4,
  },
  {
    name: 5,
    value: 5,
  },
  {
    name: 6,
    value: 6,
  },
  {
    name: 7,
    value: 7,
  },
  {
    name: 8,
    value: 8,
  },
  {
    name: 9,
    value: 9,
  },
  {
    name: 10,
    value: 10,
  },
]

export default [
  {
    fieldsetName: 'Order Details',
    items: [
      {
        type: 'text',
        name: 'name',
        placeholder: null,
        label: 'Your Name',
        required: true,
        validationRules: 'required|min:2|max:120',
      },
      {
        type: 'text',
        name: 'email',
        placeholder: null,
        label: 'Your Email Address',
        required: true,
        validationRules: 'required|email',
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: null,
        label: 'Your Phone Number',
        required: true,
        validationRules: 'required|phone',
      },
      {
        type: 'select',
        name: 'dinnerQty',
        label: 'Number of Dinners',
        required: true,
        price: 295,
        optionsList: selectItems.slice(0, 5),
      },
      {
        type: 'select',
        name: 'pie',
        label: 'Pie Choice',
        required: true,
        optionsList: [
          { name: 'Dutch Apple Pie', value: 'apple' },
          { name: 'Pumpkin Pie', value: 'pumpkin' },
        ],
      },
      {
        type: 'select',
        name: 'dinnerType',
        label: 'Dinner Type (traditional or gluten free)',
        required: true,
        optionsList: [
          { name: 'Traditional', value: 'traditional' },
          { name: 'Gluten Free + $20', value: 'glutenFree' },
        ],
      },
    ],
  },
  {
    fieldsetName: 'Extra Sides',
    items: [
      {
        type: 'select',
        name: 'extraTurkeyQty',
        placeholder: null,
        label: 'Turkey',
        required: false,
        price: 125,
        optionsList: selectItems,
      },
      {
        type: 'select',
        name: 'extraBreadQty',
        placeholder: null,
        label: 'Focaccia Bread',
        required: false,
        price: 7,
        optionsList: selectItems,
      },
      {
        type: 'select',
        name: 'extraSaladQty',
        placeholder: null,
        label: 'Salad',
        required: false,
        price: 14,
        optionsList: selectItems,
      },
      {
        type: 'select',
        name: 'extraBeansQty',
        placeholder: null,
        label: 'Green Beans',
        required: false,
        price: 25,
        optionsList: selectItems,
      },
      {
        type: 'select',
        name: 'extraStuffingQty',
        placeholder: null,
        label: 'Stuffing',
        required: true,
        validationRules: null,
        price: 25,
        optionsList: selectItems,
      },
      {
        type: 'select',
        name: 'extraCranberriesQty',
        placeholder: null,
        label: 'Cranberry Sauce',
        required: true,
        price: 13,
        optionsList: selectItems,
        validationRules: null,
      },
      {
        type: 'select',
        name: 'extraPotatoesQty',
        placeholder: null,
        label: 'Whipped Potatoes',
        required: false,
        price: 25,
        validationRules: null,
        optionsList: selectItems,
      },
      {
        type: 'select',
        name: 'extraGravyQty',
        placeholder: null,
        label: 'Gravy',
        required: false,
        price: 16,
        validationRules: null,
        optionsList: selectItems,
      },
      {
        type: 'select',
        name: 'extraCarrotsQty',
        placeholder: null,
        label: 'Carrots',
        required: false,
        price: 24,
        validationRules: null,
        optionsList: selectItems,
      },
    ],
  },
  {
    fieldsetName: 'Add Extra Pies',
    items: [
      {
        type: 'select',
        name: 'extraApplePieQty',
        placeholder: null,
        label: 'Mag’s Dutch Apple Pie',
        required: false,
        price: 20,
        validationRules: null,
        optionsList: selectItems,
      },
      {
        type: 'select',
        name: 'extraPumpkinPieQty',
        placeholder: null,
        label: 'Pumpkin Pie',
        required: false,
        price: 20,
        validationRules: null,
        optionsList: selectItems,
      },
    ],
  },
  // {
  //   fieldsetName: 'Sparkling Wines',
  //   items: [
  //     {
  //       type: 'select',
  //       name: 'vernayQty',
  //       placeholder: null,
  //       label: 'Veuve de Vernay',
  //       required: false,
  //       price: 15,
  //       optionsList: selectItems,
  //       validationRules: null,
  //     },
  //     {
  //       type: 'select',
  //       name: 'seccoQty',
  //       placeholder: null,
  //       label: 'Secco Rosé',
  //       required: false,
  //       price: 20,
  //       optionsList: selectItems,
  //       validationRules: null,
  //     },
  //     {
  //       type: 'select',
  //       name: 'laurentQty',
  //       placeholder: null,
  //       label: 'Laurent-Perrier Rosé',
  //       required: false,
  //       price: 99,
  //       optionsList: selectItems,
  //       validationRules: null,
  //     },
  //   ],
  // },
  // {
  //   fieldsetName: 'Red Wines',
  //   items: [
  //     {
  //       type: 'select',
  //       name: 'firesteadQty',
  //       placeholder: null,
  //       label: 'Firestead Pinot',
  //       required: false,
  //       price: 20,
  //       optionsList: selectItems,
  //       validationRules: null,
  //     },
  //     {
  //       type: 'select',
  //       name: 'lapisQty',
  //       placeholder: null,
  //       label: 'Lapis Zinfandel',
  //       required: false,
  //       price: 20,
  //       optionsList: selectItems,
  //       validationRules: null,
  //     },
  //     {
  //       type: 'select',
  //       name: 'ancientQty',
  //       placeholder: null,
  //       label: 'Ancient Peaks Cabernet',
  //       required: false,
  //       price: 20,
  //       optionsList: selectItems,
  //       validationRules: null,
  //     },
  //     {
  //       type: 'select',
  //       name: 'adelsheimQty',
  //       placeholder: null,
  //       label: 'Adelsheim Pinot Noir',
  //       required: false,
  //       price: 75,
  //       optionsList: selectItems,
  //       validationRules: null,
  //     },
  //     {
  //       type: 'select',
  //       name: 'cakebreadQty',
  //       placeholder: null,
  //       label: 'Cakebread',
  //       required: false,
  //       price: 99,
  //       optionsList: selectItems,
  //       validationRules: null,
  //     },
  //   ],
  // },
  // {
  //   fieldsetName: 'White Wines',
  //   items: [
  //     {
  //       type: 'select',
  //       name: 'annalisaQty',
  //       placeholder: null,
  //       label: 'Annalisa Pinot Grigio',
  //       required: false,
  //       price: 15,
  //       optionsList: selectItems,
  //       validationRules: null,
  //     },
  //     {
  //       type: 'select',
  //       name: 'emmoloQty',
  //       placeholder: null,
  //       label: 'Emmolo Sauvignon Blanc',
  //       required: false,
  //       price: 20,
  //       optionsList: selectItems,
  //       validationRules: null,
  //     },
  //     {
  //       type: 'select',
  //       name: 'decoyQty',
  //       placeholder: null,
  //       label: 'Decoy Chardonnay',
  //       required: false,
  //       price: 20,
  //       optionsList: selectItems,
  //       validationRules: null,
  //     },
  //     {
  //       type: 'select',
  //       name: 'chassagneQty',
  //       placeholder: null,
  //       label: 'Chassagne-Montrachet',
  //       required: false,
  //       price: 99,
  //       optionsList: selectItems,
  //       validationRules: null,
  //     },
  //   ],
  // },
  {
    fieldsetName: 'Order Requests',
    items: [
      {
        type: 'textArea',
        name: 'requests',
        placeholder:
          'We will do our best to accomodate any food allergies, or any special instructions.',
        label: 'Requests',
        required: false,
        validationRules: null,
      },
    ],
  },
]
